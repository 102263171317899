
$( window ).bind( "orientationchange", function() {
    // googletag.pubads().refresh([topBarBanner]);
    googletag.pubads().refresh([searchTopBanner]);
    googletag.pubads().refresh([searchBannerOne]);
    googletag.pubads().refresh([searchBannerTwo]);
    googletag.pubads().refresh([programsTopBanner]);
    googletag.pubads().refresh([programsBannerOne]);
    googletag.pubads().refresh([programsBannerTwo]);
    googletag.pubads().refresh([pagesTopBanner]);
});